
import Vue from "vue";
import CardView from "./components/CardView.vue";
import FooterView from "./components/FooterView.vue";
import { ethers } from "ethers";
import { dataStore } from "./states/states";


export default Vue.extend({
  name: "App",

  data: () => ({
    //
    on: false,
    items: [
      {
        title: "Explorer",
        url: "https://explorer.all.tz",
      },
      {
        title: "Faucet",
        url: "https://faucet.all.co.tz",
      },
      {
        title: "Wallet",
        url: "/Wallet",
      },
      {
        title: "dapps",
        url: "/dapps",
      },
    ],
    dataStore,
  }),
  components: {
    CardView,
    FooterView,
  },
  methods: {
    open(url: string) {
      window.open(url);
    },
  },
  mounted: async () => {
    setInterval(async () => {      
      let provider = new ethers.providers.JsonRpcProvider({
        url: "https://rpc.all.tz",
      });

      let blocks = await provider.getBlockNumber();

      let cBlock = await provider.getBlock(blocks);
      let difficulty = (cBlock.difficulty / 1000000)
        .toFixed(6)
        .toString()
        .split("");
      difficulty.pop();
      difficulty.pop();
      difficulty.pop();

      let difficult2 = "";
      difficulty.forEach((e) => {
        difficult2 += e;
      });
      console.log(cBlock.difficulty);
      difficult2 = difficult2 + "M";

      // Put commas
      let gLimit =
        cBlock.gasLimit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        " m/s";

      dataStore.gLimit = gLimit;
      dataStore.blocks = blocks.toString();
      dataStore.currentDiff = difficult2;
    }, 1000);
  },
});
